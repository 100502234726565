import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import { required, integer,confirmed,min } from "vee-validate/dist/rules";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", required);
extend("integer", integer);
extend("confirmed", confirmed);
extend("min", min);