//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['error'],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
    }
  }
}
