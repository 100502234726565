//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Navbar',
  data: () => ({
    navbar: [],
    popupOpen: false,
    discoverNavActive: false,
    isVisible: true,
    previousScroll: 0,
    navLanguageDropdown: false,
    navUserDropdown: false,
    navServicesDropdown: false,
    navSearchDropdown: false,
    mobileShowMenu: false,
    subMenuActive: 0,
    formOpenResult: false,
    searchKey: '',
    searchResult: [],
    shareOpen: false,
    shareDesktopOpen: false,
    // isLoading:true

  }),

  computed: {
    // availableLocales() {
    //   return this.$i18n.locales.filter(i => i !== this.$i18n.locale)
    // },
    availableLocales() {
      if (this.$store.state.availableLanguages) {
        return this.$store.state.availableLanguages.filter(i => i !== this.$i18n.locale)
      }
    },


    generaleSettings() {
      return this.$store.state.generaleSettings
    },
    socialMedia() {
      return this.$store.state.socialMedia
    },

    userImage() {
      return this.$auth.user.photo ?
        this.$auth.user.photo : this.$auth.user.picture ?
          (this.$auth.user.picture.data ? this.$auth.user.picture.data.url : this.$auth.user.picture) :
          this.$auth.user.profile_photo_url ? this.$auth.user.profile_photo_url : '/images/profile.svg'
    },

  },

  mounted() {
    var _this = this
    document.addEventListener("click", function (event) {
      var trigger = document.getElementsByClassName('discover-nav')[0]
      if (trigger) {
        var discoverInsideElement = trigger.contains(event.target);
        if (!discoverInsideElement) {
          _this.discoverNavActive = false
        }
      }

      var searchTrigger = document.getElementsByClassName('main-navbar')[0];
      if (searchTrigger) {
        if (!searchTrigger.contains(event.target)) {
          _this.formOpenResult = false
          _this.navSearchDropdown = false
          _this.searchKey = ''
        }
      }
      var shareTrigger = document.getElementsByClassName('share-buttons-desktop')[0];
      if (shareTrigger) {
        if (!shareTrigger.contains(event.target)) {
          _this.shareDesktopOpen = false
        }
      }
    })

    document.addEventListener("click", function (event) {
      var trigger = document.getElementsByClassName('main-navbar')[0];
      if (trigger) {
        var mainInsideElement = trigger.contains(event.target);
        if (!mainInsideElement) {
          _this.mobileShowMenu = false
        }
      }
    });

    setTimeout(() => {
      if (window.innerWidth > 992) {
        window.addEventListener('scroll', this.stickNavbar);
      }
    }, 500);
    const mobileNav = document.querySelector('.bottom-fixed-nav')
    window.addEventListener('touchstart', function () {
      // console.log(window.innerWidth)
      if (window.innerWidth <= 992 && mobileNav.style.display !== "block") {
        mobileNav.style.display = "block"
      }
    }, {passive: true});
  },

  methods: {
    handlePopupOpen() {
      this.popupOpen = true
      this.$nextTick(() => {
        this.$refs.popup.openPopup();
      });
    },
    stickNavbar() {
      let body = document.body,
        html = document.documentElement;

      let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      let currentScroll = window.scrollY
      if (currentScroll == 0) {
        this.isVisible = true
        return
      }
      if (currentScroll > 10 && currentScroll < height - screen.height) {
        if (currentScroll > this.previousScroll) {
          this.isVisible = false
        } else {
          this.isVisible = true
        }
        this.previousScroll = currentScroll;
      }
    },

    async globalSearch() {
      if (this.searchKey.length > 3) {
        const response = await this.$api.globalSearch(this.searchKey)
        this.searchResult = response.content
        this.formOpenResult = true
      } else {
        this.formOpenResult = false
      }
    },

    inputBlur() {
      if (this.searchKey.length > 3) {
        this.formOpenResult = true
      }
    },

    async logout() {
      await this.$auth.logout()
      this.$toast.success(`${this.$i18n.t('loginPage.logout')}`)
    }
  },

  destroyed() {
    window.removeEventListener('scroll', this.stickNavbar);
  },

  async fetch() {
    try {
      // this.isLoading = true
      const response = await this.$api.fetchNavbar()
      this.navbar = response.content
      // this.isLoading = false
    } catch (e) {
      console.error(e)
      // this.isLoading = false
    }
  }
  // fetchOnServer:false

}
