export default ($apiClient) => ({
  fetchBlog() {
    return $apiClient.$get("/blog");
  },

  latestPosts(page) {
    return $apiClient.$get(`/blog/latest-posts?page=${page}&limit=10`);
  },

  fetchSinglePost(post) {
    return $apiClient.$get(`/blog/${post}`);
  },

  fetchCategoryPost(categoryPost,page) {
    return $apiClient.$get(`/blog/category/${categoryPost}?page=${page}&limit=10`);
  },

  fetchFaqsCategories() {
    return $apiClient.$get("/faqs/categories");
  },

  fetchFaqs() {
    return $apiClient.$get("/faqs");
  },

  faqSearch(key) {
    return $apiClient.$get(`/faqs/search?key=${key}`)
  },

  fetchServices() {
    return $apiClient.$get("/services/turkey");
  },

  fetchCategoryFaq(categoryFaq) {
    return $apiClient.$get(`/faqs/${categoryFaq}`);
  },

  fetchSinglePage(page) {
    return $apiClient.$get(`/pages/${page}`);
  },

  fetchContactUsPage() {
    return $apiClient.$get(`/pages/contact-us`);
  },

  fetchHospitals(country) {
    return $apiClient.$get(`/hospitals/${country}`);
  },

  fetchHospitalsInCity(country, city) {
    return $apiClient.$get(`/hospitals/${country}/${city}&limit=6`);
  },

  fetchSingleHospital(country, city, slug) {
    return $apiClient.$get(`/hospitals/${country}/${city}/${slug}`);
  },

  fetchDoctors(country) {
    return $apiClient.$get(`/doctors/${country}`);
  },

  fetchDoctorsInCity(country, city,page) {
    return $apiClient.$get(`/doctors/${country}/${city}?page=${page}&limit=6`);
  },

  fetchDoctorsInCityInSpecializations(country, city, specialization,page) {
    return $apiClient.$get(`/doctors/${country}/${city}/${specialization}?page=${page}&limit=6`);
  },

  fetchSingleDoctor(country, city, specialization, doctor) {
    return $apiClient.$get(`/doctors/${country}/${city}/${specialization}/${doctor}`);
  },

  fetchHospitalsState(country) {
    return $apiClient.$get(`/hospitals/${country}/states`);
  },

  fetchDoctorState(country) {
    return $apiClient.$get(`/doctors/${country}/states`);
  },

  fetchNavbar() {
    return $apiClient.$get(`/menus/1`);
  },

  fetchFooter() {
    return $apiClient.$get(`/menus/2`);
  },

  fetchSocialMedia() {
    return $apiClient.$get(`/settings/social-settings`);
  },

  fetchGeneraleSettings() {
    return $apiClient.$get(`/settings/general-settings`);
  },

  fetchMedicalSections() {
    return $apiClient.$get(`/services/turkey`);
  },

  fetchQuizzes() {
    return $apiClient.$get(`/quizzes`);
  },

  fetchCalculators() {
    return $apiClient.$get(`/calculators`);
  },

  fetchSingleQuiz(quiz) {
    return $apiClient.$get(`/quizzes/${quiz}`);
  },

  fetchState(country) {
    return $apiClient.$get(`/filters/countries/${country}`);
  },

  fetchSpas() {
    return $apiClient.$get(`/resorts`);
  },

  fetchSingleSpa(spa) {
    return $apiClient.$get(`/resorts/${spa}`);
  },

  fetchCostPage() {
    return $apiClient.$get(`/costs`);
  },

  fetchSingleServices(services) {
    return $apiClient.$get(`/services/turkey/${services}`);
  },

  fetchCostsList() {
    return $apiClient.$get(`/home/cost`);
  },

  fetchHomePage() {
    return $apiClient.$get(`/home`);
  },

  fetchHomeTestimonials() {
    return $apiClient.$get(`/home/testimonials`);
  },

  fetchHomeCost() {
    return $apiClient.$get(`/home/cost`);
  },

  fetchHomeServices() {
    return $apiClient.$get(`/services/turkey/list`);
  },

  fetchHomecountry() {
    return $apiClient.$get(`/costs/countries`);
  },

  fetchHomeTreatment() {
    return $apiClient.$get(`/costs/services`);
  },

  fetchCostResult(serviceId, countryId, travellers, stars) {
    return $apiClient.$post(
      `/costs/get-price?serviceId=${serviceId}&stars=${stars}&travellers=${travellers}&countryId=${countryId}`
    );
  },

  fetchHomeHospitals() {
    return $apiClient.$get(`/home/hospitals`);
  },

  fetchHomeDoctors() {
    return $apiClient.$get(`/home/doctors`);
  },

  fetchHomeDoctorsSpecializations() {
    return $apiClient.$get(`/home/specializations`);
  },

  fetchHomeTv() {
    return $apiClient.$get(`/home/tv`);
  },

  fetchSingleCalculator(calculator) {
    return $apiClient.$get(`/calculators/${calculator}`);
  },

  fetchOnlineTreatment() {
    return $apiClient.$get(`/pages/online-treatment`);
  },

  fetchOnlineTreatmentLevel1() {
    return $apiClient.$get(`/online-treatment`);
  },

  fetchOnlineTreatmentLevel2(slug) {
    return $apiClient.$get(`/online-treatment/${slug}`);
  },

  globalSearch(key) {
    return $apiClient.$get(`/global-search?key=${key}`);
  },

  fetchSingleTag(tag, page,limit ,category) {
    return $apiClient.$get(`/tags/${tag}?page=${page}&limit=${limit}&in=${category}`);
  },

  contactFormSend(data) {
    return $apiClient.$post(`/home/send-message`, data);
  },

  subscribeForm(data) {
    return $apiClient.$post(`/home/subscribe-mail`, data);
  },

  uploadImage(data) {
    return $apiClient.$post(`/uploading-image`, data);
  },

  register(user) {
    return $apiClient.$post(`/register`,user)
  },

  // doctor dashboard
  fetchDoctorWallet(page) {
    return $apiClient.$get(`/doctor/transactions?page=${page}`);
  },

  fetchSupport() {
    return $apiClient.$get("/ilajak-online/faq");
  },

  fetchScheduleList(today,page) {
    return $apiClient.$get(`/doctor/meetings?time=${today}&page=${page}&limit=5`);
  },

  fetchDuration() {
    return $apiClient.$get("/doctor/durations");
  },

  fetchPrice() {
    return $apiClient.$get("/doctor/prices");
  },

  deletePrice(PriceId) {
    return $apiClient.$delete(`/doctor/price-plan/${PriceId}`);
  },

  updatePrice(durationId,price) {
    return $apiClient.$post(`/doctor/price-plan`,{duration_id:durationId,price:price});
  },

  fetchSchedule() {
    return $apiClient.$get(`/doctor/schedule`);
  },

  addSession(session) {
    return $apiClient.$post(`/doctor/schedule`,session);
  },

  deleteSession(session) {
    return $apiClient.$post(`/doctor/schedule`,session);
  },

  fetchProfile() {
    return $apiClient.$get(`/profile`);
  },

  updateProfile(profile) {
    return $apiClient.$post(`/profile`,profile);
  },

  updateProfileImage(photo) {
    return $apiClient.$post(`/profile-photo`,photo);
  },

  fetchEducationList() {
    return $apiClient.$get(`/doctor/qualifications`)
  },

  fetchSpecializationList() {
    return $apiClient.$get(`/doctor/online-specializations`)
  },

  fetchTreatmentsList() {
    return $apiClient.$get(`/doctor/treatments`)
  },

  fetchCertificate() {
    return $apiClient.$get(`/doctor/certificate`)
  },

  saveOrUpdateCertificates(certificateForm) {
    return $apiClient.$post(`/doctor`,certificateForm,{
      Headers:{
        "mimeType": "multipart/form-data",
      }
    });
  },

  fetchBankAccounts() {
    return $apiClient.$get(`/doctor/bank-accounts`);
  },

  addBankAccount(account) {
    return $apiClient.$post(`/doctor/bank-account`,account);
  },

  updateBankAccount(accountInfo) {
    return $apiClient.$post(`/doctor/bank-account/${accountInfo.id}`,accountInfo);
  },

  deleteBankAccount(id) {
    return $apiClient.$delete(`/doctor/bank-account/${id}`);
  },

  changePassword(current,newPassword,confirmPassword) {
    return $apiClient.$post(`/change-password`,{old_password:current,new_password:newPassword,confirm_password:confirmPassword});
  },

  closeMyAccount() {
    return $apiClient.$post(`/close-account`);
  },

  fetchProfileProgress() {
    return $apiClient.$get(`/profile-complete-percentage`);
  },

  fetchReportList() {
    return $apiClient.$get(`/ilajak-online/reports`);
  },

  addNewReport(report) {
    return $apiClient.$post(`/ilajak-online/add-report`,{title:report});
  },

  reportMeeting(reportId,meetingId) {
    return $apiClient.$post(`/ilajak-online/report-meeting/${meetingId}`,{report_id:reportId});
  },

  sendCaseReview(doctorNote,meetingId) {
    return $apiClient.$post(`/doctor/meetings/${meetingId}`,{report:doctorNote});
  },

  fetchFreeTimes() {
    return $apiClient.$get(`/doctor/unavailable-times`);
  },

  addFreeTime(freeTime) {
    return $apiClient.$post(`/doctor/unavailable-times`,freeTime);
  },

  deleteFreeTime(freeTime) {
    return $apiClient.$post(`/doctor/unavailable-times`,freeTime);
  },

  fetchDoctorMeet(id) {
    return $apiClient.$get(`/doctor/meetings/${id}`)
  },

  closeMeeting(id) {
    return $apiClient.$post(`/doctor/meetings/${id}`,{action:'Closed'})
  },

  addMeetReply(id,reply) {
    return $apiClient.$post(`/doctor/meeting/${id}/add-reply`,{reply:reply})
  },

  // patient dashboard
  fetchPatientWallet(page) {
    return $apiClient.$get(`/patient/transactions?page=${page}&limit=8`);
  },

  fetchExperts(page){
    return $apiClient.$get(`/patient/appointment-page?page=${page}&limit=8`)
  },

  fetchExpertsBySpecializations(slug){
    return $apiClient.$get(`/patient/appointment-page/${slug}?limit=8`)
  },

  fetchAppointments(page){
    return $apiClient.$get(`/patient/meetings?page=${page}&limit=9`)
  },

  fetchPatientMeet(id) {
    return $apiClient.$get(`/patient/meetings/${id}`)
  },

  fetchPatientMeetReply(id) {
    return $apiClient.$get(`/patient/meetings/${id}/replies`)
  },

  addPatientMeetReply(id,reply) {
    return $apiClient.$post(`/patient/meeting/${id}/reply`,{reply:reply})
  },

  cancelPatientMeet(id) {
    return $apiClient.$post(`/patient/cancel-meeting/${id}`)
  },

  fetchPackages() {
    return $apiClient.$get(`patient/bundles`)
  },

  fetchDoctorSchedule(slug) {
    return $apiClient.$get(`ilajak-online/meetings/${slug}`)
  },

  payPackage(id) {
    return $apiClient.$post(`/patient/take-bundle/${id}`)
  },

  takeAppointment(appointment) {
    return $apiClient.$post(`/patient/request-meeting`,appointment)
  }
});
