

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":{"en":["fr","ar"],"fr":["en","ar"],"ar":["en","fr"],"tr":["en","fr"]}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.js","dir":"ltr"},{"code":"ar","iso":"ar","file":"ar.js","dir":"rtl"},{"code":"fr","iso":"fr","file":"fr.js","dir":"ltr"},{"code":"tr","iso":"tr","file":"tr.js","dir":"ltr"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "~/lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://ilajak.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.js","dir":"ltr"},{"code":"ar","iso":"ar","file":"ar.js","dir":"rtl"},{"code":"fr","iso":"fr","file":"fr.js","dir":"ltr"},{"code":"tr","iso":"tr","file":"tr.js","dir":"ltr"}],
  localeCodes: ["en","ar","fr","tr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "n",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "a",
  27: "r",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "a",
  43: "r",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ",",
  49: "\"",
  50: "f",
  51: "r",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "a",
  63: "n",
  64: "g",
  65: "/",
  66: "f",
  67: "r",
  68: ".",
  69: "j",
  70: "s",
  71: "\"",
  72: ",",
  73: "\"",
  74: "t",
  75: "r",
  76: ".",
  77: "j",
  78: "s",
  79: "\"",
  80: ":",
  81: "\"",
  82: ".",
  83: ".",
  84: "/",
  85: "l",
  86: "a",
  87: "n",
  88: "g",
  89: "/",
  90: "t",
  91: "r",
  92: ".",
  93: "j",
  94: "s",
  95: "\"",
  96: "}",
}

export const localeMessages = {
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'ar.js': () => import('../../lang/ar.js' /* webpackChunkName: "lang-ar.js" */),
  'fr.js': () => import('../../lang/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'tr.js': () => import('../../lang/tr.js' /* webpackChunkName: "lang-tr.js" */),
}
