export default function ({ $axios, i18n, app, route }, inject) {
  const apiClient = $axios.create({
    headers: {
      'X-localization': i18n.locale,
    }
  })
  apiClient.onResponse(response => {
    //Exclude "services/turkey" because it exists in every page in the sidebar slider,
    // and it has all the languages available
    // which conflict with blog pages that doesn't support all website languages
    if (response.config.url !== ('/services/turkey')) {
      if (response && response.data && response.data.content && response.data.content.meta_tags) {
        let langArr = Object.keys(response.data.content.meta_tags.alternative)
        //useful for debugging languages and urls of each response
        // console.log(langArr, response.config.url)
        app.store.dispatch('setAvailableLanguages', langArr)
      }
    }
    return response;
  })
  inject('apiClient', apiClient)
}
