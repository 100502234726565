//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate,
  },
  data() {

    return {
      listenersStarted: false,
      idToken: "",
      showPopup: false
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      title: (this.generaleSettings.find(x => x.slug === 'site_name') && this.generaleSettings.find(x => x.slug === 'site_name').value) ? this.generaleSettings.find(x => x.slug === 'site_name').value : 'ilajak',
      script: [

        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/js/all.min.js",
          defer: true
        },
        {
          src: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js",
          defer: true
        },

      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ilajak.com' + this.$route.path
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css"
        },
        {
          rel: "preload",
          href: "https://accounts.google.com/gsi/client",
          defer: true,
        },
        {
          rel: "stylesheet preload",
          href: this.$i18n.locale == 'ar' ? "https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css" : "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css",
        },
        {
          rel: "stylesheet",
          href: "https://cdn.jsdelivr.net/npm/@fancyapps/ui/dist/fancybox.css",
          media: "print",
          onload: "this.media='all'"
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.4.5/css/swiper.min.css",
          media: "print",
          onload: "this.media='all'"
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/vue-multiselect/2.1.6/vue-multiselect.min.css",
          media: "print",
          onload: "this.media='all'"
        },
        {
          rel: "preload stylesheet",
          href: this.$i18n.locale == 'ar' ? 'https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap' : "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap",

        },
      ],
      meta: [
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@ilajakCom'
        },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: '@ilajakCom'
        },
        {
          name: "robots",
          content: "index, follow"
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'article'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: (this.generaleSettings.find(x => x.slug === 'site_name') && this.generaleSettings.find(x => x.slug === 'site_name').value) ? this.generaleSettings.find(x => x.slug === 'site_name').value : 'ilajak'
        },
      ],
      bodyAttrs: {
        class: this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'
      }
    }
  },

  computed: {
    generaleSettings() {
      return this.$store.state.generaleSettings
    }
  },

  async fetch() {
    await this.$store.dispatch('getSocialMedia');
    await this.$store.dispatch('getGeneraleSettings');
  },

  //Watch route changes to perform language Manipulation on (blog posts) only
  // watch: {
  //   $route(to, from) {
  //     if (!(to.fullPath.includes('/blog/')) || (to.fullPath.includes('/blog/category'))) {
  //       this.$store.commit('ALL_LANGUAGES')
  //     }
  //   },
  // },
  methods: {
    async handleCredentialResponse(x) {
      const responsePayload = this.decodeJwtResponse(x.credential)
      let dataGoogle = {
        provider_id: responsePayload.sub,
        name: responsePayload.name,
        email: responsePayload.email,
        avatar: responsePayload.picture,
        provider: 'google',
      }
      try {
        const response = await this.$axios.$post("login/social", dataGoogle)
        await this.$auth.setStrategy('google')
        await this.$auth.$storage.setUniversal('user', response.content, true)
        await this.$auth.setUser({
          user: responsePayload.name,
          email: responsePayload.email,
          image: responsePayload.picture,
        });
      } catch (e) {
        //console.log(e);
      }
    },


    decodeJwtResponse(credential) {
      var base64Url = credential.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },

    // FCM NOTIFICATION FUNCTIONS
    async startListeners() {
      await this.startOnMessageListener();
      // await this.startTokenRefreshListener();
      await this.requestPermission();
      // await this.initRegistration();
      this.listenersStarted = true;
      await this.initPush()
      await this.initRegistration()
    },
    startOnMessageListener() {
      try {
        this.$fire.messaging.onMessage((payload) => {
          // console.log("This is the payload: ", payload);
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: payload.notification.icon,
            image: payload.notification.image
          };

          if (Notification.permission === 'granted') {
            var notification = new Notification(notificationTitle, notificationOptions);
            notification.onclick = function (ev) {
              ev.preventDefault();
              window.open(payload.notification.click_action, '_blank');
              notification.close();
            }
          }
        });
      } catch (e) {
        console.error("Error : ", e);
      }
    },

    async requestPermission() {
      try {
        const permission = await Notification.requestPermission();
        // console.log("GIVEN notify perms");
        console.log(permission);
      } catch (e) {
        console.error("Error : ", e);
      }
    },


    initRegistration() {

      Notification
        // Request permission
        .requestPermission()
        // Get token
        .then(() => {
          return this.$fire.messaging.getToken();
        })
        // Store the token
        .then(async function (token) {
          localStorage.setItem("endpoint_token", token);
          var data = {
            locale: document.documentElement.lang,
            subscription: token,
          };

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Origin", "*");
          myHeaders.append("Accept", "application/json");
          await fetch("https://notify.imtilakgroup.com/api/subscribe", {
            method: "POST",
            headers: myHeaders,
            mode: "cors",
            data: JSON.stringify(data),
            body: JSON.stringify({
              locale: document.documentElement.lang,
              subscription: token,
            }),
          }).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initPush() {
      // if (!swReady) {
      //   return;
      // }
      // console.log('init push here');
      new Promise((resolve, reject) => {
        const permissionResult = Notification.requestPermission((result) => {
          resolve(result);
        });

        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }).then((permissionResult) => {
        if (permissionResult == "denied") {
          this.removeSubscriber();
        } else if (permissionResult == "granted 131") {
          this.initRegistration();
        }
      });
    },
    removeSubscriber() {
      // get the stored idToken from the server for the client
      var idToken = localStorage.getItem("endpoint_token");

      // store the gotten idToken in the data object
      const data = {
        token: idToken,
      };

      // Fetch the server again to unsubscribe API to remove using delete method instead of post as we are going to apply removing not posting
      fetch("https://notify.imtilakgroup.com/api/unsubscribe", {
        method: "delete",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        // In case there any error return the error and print it in console
        .catch((err) => {
          console.log(err);
        });
    },

  },

  mounted() {
    // this.$store.dispatch('getUserLocation')
    if (process.client) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.umd.js";
      script.defer = true
      document.head.appendChild(script);
    }

    setTimeout(() => {
      this.showPopup = true
    }, 5000) // wait for 5 seconds before showing popup

    //   var _this = this
    //   if(!this.$auth.$state.loggedIn) {
    //     window.onload = function () {
    //       google.accounts.id.initialize({
    //         client_id: '42786869263-m18o849h1a847k5mc2ceanpkupdnfeq9.apps.googleusercontent.com',
    //         callback: _this.handleCredentialResponse,
    //         state_cookie_domain:'ilajak.com'
    //       });
    //       google.accounts.id.prompt();
    //     };
    //   }

    setTimeout(() => {
      this.startListeners();
    }, 10000);


  },

};
