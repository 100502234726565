export const BlogCard = () => import('../../components/BlogCard.vue' /* webpackChunkName: "components/blog-card" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const CalcCard = () => import('../../components/CalcCard.vue' /* webpackChunkName: "components/calc-card" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const CookiesGlobal = () => import('../../components/CookiesGlobal.vue' /* webpackChunkName: "components/cookies-global" */).then(c => wrapFunctional(c.default || c))
export const CookiesTR = () => import('../../components/CookiesTR.vue' /* webpackChunkName: "components/cookies-t-r" */).then(c => wrapFunctional(c.default || c))
export const CostSection = () => import('../../components/CostSection.vue' /* webpackChunkName: "components/cost-section" */).then(c => wrapFunctional(c.default || c))
export const DoctorCard = () => import('../../components/DoctorCard.vue' /* webpackChunkName: "components/doctor-card" */).then(c => wrapFunctional(c.default || c))
export const ExpertCard = () => import('../../components/ExpertCard.vue' /* webpackChunkName: "components/expert-card" */).then(c => wrapFunctional(c.default || c))
export const FaqSection = () => import('../../components/FaqSection.vue' /* webpackChunkName: "components/faq-section" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HospitalCard = () => import('../../components/HospitalCard.vue' /* webpackChunkName: "components/hospital-card" */).then(c => wrapFunctional(c.default || c))
export const MedicalSections = () => import('../../components/MedicalSections.vue' /* webpackChunkName: "components/medical-sections" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NewDentistryLandingContactForm = () => import('../../components/NewDentistryLandingContactForm.vue' /* webpackChunkName: "components/new-dentistry-landing-contact-form" */).then(c => wrapFunctional(c.default || c))
export const OffersSection = () => import('../../components/OffersSection.vue' /* webpackChunkName: "components/offers-section" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumber = () => import('../../components/PhoneNumber.vue' /* webpackChunkName: "components/phone-number" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const ProductSection = () => import('../../components/ProductSection.vue' /* webpackChunkName: "components/product-section" */).then(c => wrapFunctional(c.default || c))
export const QuizCard = () => import('../../components/QuizCard.vue' /* webpackChunkName: "components/quiz-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceCard = () => import('../../components/ServiceCard.vue' /* webpackChunkName: "components/service-card" */).then(c => wrapFunctional(c.default || c))
export const ShareMedia = () => import('../../components/ShareMedia.vue' /* webpackChunkName: "components/share-media" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const SideServices = () => import('../../components/SideServices.vue' /* webpackChunkName: "components/side-services" */).then(c => wrapFunctional(c.default || c))
export const SocialMedia = () => import('../../components/SocialMedia.vue' /* webpackChunkName: "components/social-media" */).then(c => wrapFunctional(c.default || c))
export const SpaCard = () => import('../../components/SpaCard.vue' /* webpackChunkName: "components/spa-card" */).then(c => wrapFunctional(c.default || c))
export const TravelCost = () => import('../../components/TravelCost.vue' /* webpackChunkName: "components/travel-cost" */).then(c => wrapFunctional(c.default || c))
export const DashboardContactBottom = () => import('../../components/Dashboard/ContactBottom.vue' /* webpackChunkName: "components/dashboard-contact-bottom" */).then(c => wrapFunctional(c.default || c))
export const DashboardSupport = () => import('../../components/Dashboard/Support.vue' /* webpackChunkName: "components/dashboard-support" */).then(c => wrapFunctional(c.default || c))
export const DashboardZoomWindow = () => import('../../components/Dashboard/ZoomWindow.vue' /* webpackChunkName: "components/dashboard-zoom-window" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardCaseReviewModal = () => import('../../components/DoctorDashboard/CaseReviewModal.vue' /* webpackChunkName: "components/doctor-dashboard-case-review-modal" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardNotVerified = () => import('../../components/DoctorDashboard/NotVerified.vue' /* webpackChunkName: "components/doctor-dashboard-not-verified" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardPrice = () => import('../../components/DoctorDashboard/Price.vue' /* webpackChunkName: "components/doctor-dashboard-price" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardReportMeetingModal = () => import('../../components/DoctorDashboard/ReportMeetingModal.vue' /* webpackChunkName: "components/doctor-dashboard-report-meeting-modal" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardReviewModal = () => import('../../components/DoctorDashboard/ReviewModal.vue' /* webpackChunkName: "components/doctor-dashboard-review-modal" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardSchedule = () => import('../../components/DoctorDashboard/Schedule.vue' /* webpackChunkName: "components/doctor-dashboard-schedule" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardSession = () => import('../../components/DoctorDashboard/Session.vue' /* webpackChunkName: "components/doctor-dashboard-session" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardTodaySchedule = () => import('../../components/DoctorDashboard/TodaySchedule.vue' /* webpackChunkName: "components/doctor-dashboard-today-schedule" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardUserInfoModal = () => import('../../components/DoctorDashboard/UserInfoModal.vue' /* webpackChunkName: "components/doctor-dashboard-user-info-modal" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardUserProfile = () => import('../../components/DoctorDashboard/UserProfile.vue' /* webpackChunkName: "components/doctor-dashboard-user-profile" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardWallet = () => import('../../components/DoctorDashboard/Wallet.vue' /* webpackChunkName: "components/doctor-dashboard-wallet" */).then(c => wrapFunctional(c.default || c))
export const DoctorDashboardStopMeetingModal = () => import('../../components/DoctorDashboard/stopMeetingModal.vue' /* webpackChunkName: "components/doctor-dashboard-stop-meeting-modal" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsFacebook = () => import('../../components/LandingComponents/Facebook.vue' /* webpackChunkName: "components/landing-components-facebook" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsFaq = () => import('../../components/LandingComponents/Faq.vue' /* webpackChunkName: "components/landing-components-faq" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsFreeConsultation = () => import('../../components/LandingComponents/FreeConsultation.vue' /* webpackChunkName: "components/landing-components-free-consultation" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsGetFreeMedical = () => import('../../components/LandingComponents/GetFreeMedical.vue' /* webpackChunkName: "components/landing-components-get-free-medical" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsGoogle = () => import('../../components/LandingComponents/Google.vue' /* webpackChunkName: "components/landing-components-google" */).then(c => wrapFunctional(c.default || c))
export const LandingContactForm = () => import('../../components/LandingComponents/LandingContactForm.vue' /* webpackChunkName: "components/landing-contact-form" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsMedicalOffers = () => import('../../components/LandingComponents/MedicalOffers.vue' /* webpackChunkName: "components/landing-components-medical-offers" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsPrices = () => import('../../components/LandingComponents/Prices.vue' /* webpackChunkName: "components/landing-components-prices" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsPrices2 = () => import('../../components/LandingComponents/Prices2.vue' /* webpackChunkName: "components/landing-components-prices2" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsTestimonials1 = () => import('../../components/LandingComponents/Testimonials1.vue' /* webpackChunkName: "components/landing-components-testimonials1" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsTestimonials2 = () => import('../../components/LandingComponents/Testimonials2.vue' /* webpackChunkName: "components/landing-components-testimonials2" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsThreeSteps = () => import('../../components/LandingComponents/ThreeSteps.vue' /* webpackChunkName: "components/landing-components-three-steps" */).then(c => wrapFunctional(c.default || c))
export const LandingComponentsWhyIlajak = () => import('../../components/LandingComponents/WhyIlajak.vue' /* webpackChunkName: "components/landing-components-why-ilajak" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardAppointmentCard = () => import('../../components/PatientDashboard/AppointmentCard.vue' /* webpackChunkName: "components/patient-dashboard-appointment-card" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardAppointments = () => import('../../components/PatientDashboard/Appointments.vue' /* webpackChunkName: "components/patient-dashboard-appointments" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardConsultDoctor = () => import('../../components/PatientDashboard/ConsultDoctor.vue' /* webpackChunkName: "components/patient-dashboard-consult-doctor" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardDoctorReviewModal = () => import('../../components/PatientDashboard/DoctorReviewModal.vue' /* webpackChunkName: "components/patient-dashboard-doctor-review-modal" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardPackageModal = () => import('../../components/PatientDashboard/PackageModal.vue' /* webpackChunkName: "components/patient-dashboard-package-modal" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardPackages = () => import('../../components/PatientDashboard/Packages.vue' /* webpackChunkName: "components/patient-dashboard-packages" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardReviewModal = () => import('../../components/PatientDashboard/ReviewModal.vue' /* webpackChunkName: "components/patient-dashboard-review-modal" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardTakeAppointment = () => import('../../components/PatientDashboard/TakeAppointment.vue' /* webpackChunkName: "components/patient-dashboard-take-appointment" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardUserProfile = () => import('../../components/PatientDashboard/UserProfile.vue' /* webpackChunkName: "components/patient-dashboard-user-profile" */).then(c => wrapFunctional(c.default || c))
export const PatientDashboardWallet = () => import('../../components/PatientDashboard/Wallet.vue' /* webpackChunkName: "components/patient-dashboard-wallet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
