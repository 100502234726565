//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Footer',
  data: () => ({
    footer: [],
    footerLinks: {},
    tabContent: 'footer-links',
    form: {
      email: ''
    }
  }),

  computed: {
    generaleSettings() {
      return this.$store.state.generaleSettings
    },

    validateEmail() {
      return (this.form.email == '') || ((/\S+@\S+\.\S+/).test(this.form.email));
    }
  },

  methods: {
    async subscribe() {
      if (this.form.email != '') {
        if (this.validateEmail) {
          try {
            const x = await this.$api.subscribeForm(this.form)
            this.$toast.success(x.message)
            this.form.email = ''
          } catch (e) {
            this.$toast.error(e.response.data.message)
          }
        }
      } else {
        return
      }
    }
  },

  async fetch() {
    const response = await this.$api.fetchFooter()
    this.footer = response.content
    this.footerLinks = this.footer.filter(i => i.slug === 'links')[0]
  },
}
