//
//
//
//
//
//
//

// import Cookies from "../components/Cookies.vue";

export default {
  name: 'empty',
  // components: {Cookies},
  head() {
    return {
      script: [
        {
          src: "https://kit.fontawesome.com/699d058cf1.js",
        },
        {
          src: "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.umd.js",
          defer: true
        },
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js",
          defer: true
        },
        {
          src: "https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js",
          defer: true
        },


        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.18/js/bootstrap-select.min.js",
          defer: true
        },

        {
          src: "https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js",
          defer: true
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href: this.$i18n.locale == 'ar' ? "https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css" : "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css",
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/vue-multiselect/2.1.6/vue-multiselect.min.css",
          defer: true
        },
        {
          rel: "stylesheet",
          href: this.$i18n.locale == 'ar' ? 'https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap' : "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap",
          defer: true
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.18/css/bootstrap-select.min.css",
          defer: true
        },
        // {
        //   rel: "stylesheet",
        //   href: "https://cdnjs.cloudflare.com/ajax/libs/vue-multiselect/2.1.6/vue-multiselect.min.css",
        //   defer:true
        // },
        // {
        //   rel: "stylesheet",
        //   href: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.4.5/css/swiper.min.css",
        //   defer:true
        // },
        {
          rel: "stylesheet",
          href: "https://cdn.jsdelivr.net/npm/@fancyapps/ui/dist/fancybox.css",
          defer: true
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.4.5/css/swiper.min.css",
          defer: true
        },
      ],
      bodyAttrs: {
        class: this.$i18n.locale == 'ar' ? 'rtl p-0 background-primary' : 'ltr p-0 background-primary'
      }
    }
  },
};
