import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1efcffa0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4ed968c7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _af18207c = () => interopDefault(import('../pages/calculators/index.vue' /* webpackChunkName: "pages/calculators/index" */))
const _247ecde6 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _3a27739c = () => interopDefault(import('../pages/cooming-soon.vue' /* webpackChunkName: "pages/cooming-soon" */))
const _440357ef = () => interopDefault(import('../pages/cost.vue' /* webpackChunkName: "pages/cost" */))
const _3e5b3808 = () => interopDefault(import('../pages/doctor-dashboard.vue' /* webpackChunkName: "pages/doctor-dashboard" */))
const _0b08b2ca = () => interopDefault(import('../pages/empty.vue' /* webpackChunkName: "pages/empty" */))
const _e5c859e2 = () => interopDefault(import('../pages/empty-default.vue' /* webpackChunkName: "pages/empty-default" */))
const _b91d6b6e = () => interopDefault(import('../pages/empty-default-footer.vue' /* webpackChunkName: "pages/empty-default-footer" */))
const _14bffb36 = () => interopDefault(import('../pages/empty-default-nav.vue' /* webpackChunkName: "pages/empty-default-nav" */))
const _be6a18fc = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _2eb6ba92 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _79acc6ba = () => interopDefault(import('../pages/online-treatment/index.vue' /* webpackChunkName: "pages/online-treatment/index" */))
const _f2d7c2cc = () => interopDefault(import('../pages/patient-dashboard.vue' /* webpackChunkName: "pages/patient-dashboard" */))
const _7b54e2c4 = () => interopDefault(import('../pages/quizzes/index.vue' /* webpackChunkName: "pages/quizzes/index" */))
const _ff8847da = () => interopDefault(import('../pages/resorts/index.vue' /* webpackChunkName: "pages/resorts/index" */))
const _5821666b = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _f2c201ba = () => interopDefault(import('../pages/tr/index.vue' /* webpackChunkName: "pages/tr/index" */))
const _663c87ca = () => interopDefault(import('../pages/landing/dental-implant1.vue' /* webpackChunkName: "pages/landing/dental-implant1" */))
const _662058c8 = () => interopDefault(import('../pages/landing/dental-implant2.vue' /* webpackChunkName: "pages/landing/dental-implant2" */))
const _660429c6 = () => interopDefault(import('../pages/landing/dental-implant3.vue' /* webpackChunkName: "pages/landing/dental-implant3" */))
const _65e7fac4 = () => interopDefault(import('../pages/landing/dental-implant4.vue' /* webpackChunkName: "pages/landing/dental-implant4" */))
const _65cbcbc2 = () => interopDefault(import('../pages/landing/dental-implant5.vue' /* webpackChunkName: "pages/landing/dental-implant5" */))
const _b98b2b2a = () => interopDefault(import('../pages/landing/ilajak-services.vue' /* webpackChunkName: "pages/landing/ilajak-services" */))
const _f78e2f2c = () => interopDefault(import('../pages/landing/ilajak-services-two.vue' /* webpackChunkName: "pages/landing/ilajak-services-two" */))
const _38640dd9 = () => interopDefault(import('../pages/landing/new-dental.vue' /* webpackChunkName: "pages/landing/new-dental" */))
const _2cf52213 = () => interopDefault(import('../pages/landing/new-dentistry.vue' /* webpackChunkName: "pages/landing/new-dentistry" */))
const _94e9caf4 = () => interopDefault(import('../pages/landing/obesity-operations.vue' /* webpackChunkName: "pages/landing/obesity-operations" */))
const _27e8d67c = () => interopDefault(import('../pages/online-treatment/level-1/index.vue' /* webpackChunkName: "pages/online-treatment/level-1/index" */))
const _3257a710 = () => interopDefault(import('../pages/services/turkey/index.vue' /* webpackChunkName: "pages/services/turkey/index" */))
const _f958463a = () => interopDefault(import('../pages/services/turkey/dentistry-turkey.vue' /* webpackChunkName: "pages/services/turkey/dentistry-turkey" */))
const _ad8d6c02 = () => interopDefault(import('../pages/services/turkey/hair-transplant-turkey.vue' /* webpackChunkName: "pages/services/turkey/hair-transplant-turkey" */))
const _1c6fc9ed = () => interopDefault(import('../pages/services/turkey/obesity-operations-turkey.vue' /* webpackChunkName: "pages/services/turkey/obesity-operations-turkey" */))
const _5f00b321 = () => interopDefault(import('../pages/services/turkey/vision-correction.vue' /* webpackChunkName: "pages/services/turkey/vision-correction" */))
const _07d9e032 = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _3cb4608a = () => interopDefault(import('../pages/online-treatment/topic/_slug.vue' /* webpackChunkName: "pages/online-treatment/topic/_slug" */))
const _30a105c8 = () => interopDefault(import('../pages/services/turkey/_slug.vue' /* webpackChunkName: "pages/services/turkey/_slug" */))
const _4d22c77f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _b285630c = () => interopDefault(import('../pages/calculators/_slug.vue' /* webpackChunkName: "pages/calculators/_slug" */))
const _19a6b612 = () => interopDefault(import('../pages/doctors/_country/index.vue' /* webpackChunkName: "pages/doctors/_country/index" */))
const _c1d75b8c = () => interopDefault(import('../pages/faqs/_slug.vue' /* webpackChunkName: "pages/faqs/_slug" */))
const _46463c12 = () => interopDefault(import('../pages/hospitals/_country/index.vue' /* webpackChunkName: "pages/hospitals/_country/index" */))
const _799e417c = () => interopDefault(import('../pages/quizzes/_slug.vue' /* webpackChunkName: "pages/quizzes/_slug" */))
const _7e853acb = () => interopDefault(import('../pages/resorts/_slug.vue' /* webpackChunkName: "pages/resorts/_slug" */))
const _a7763994 = () => interopDefault(import('../pages/tags/_slug.vue' /* webpackChunkName: "pages/tags/_slug" */))
const _1057ec9c = () => interopDefault(import('../pages/doctors/_country/_city/index.vue' /* webpackChunkName: "pages/doctors/_country/_city/index" */))
const _3568a08d = () => interopDefault(import('../pages/hospitals/_country/_city/index.vue' /* webpackChunkName: "pages/hospitals/_country/_city/index" */))
const _be08dce6 = () => interopDefault(import('../pages/doctors/_country/_city/_specialization/index.vue' /* webpackChunkName: "pages/doctors/_country/_city/_specialization/index" */))
const _d45d83f0 = () => interopDefault(import('../pages/hospitals/_country/_city/_slug/index.vue' /* webpackChunkName: "pages/hospitals/_country/_city/_slug/index" */))
const _49ab6f08 = () => interopDefault(import('../pages/doctors/_country/_city/_specialization/_slug/index.vue' /* webpackChunkName: "pages/doctors/_country/_city/_specialization/_slug/index" */))
const _1d465e58 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar",
    component: _1efcffa0,
    name: "index___ar"
  }, {
    path: "/en",
    component: _1efcffa0,
    name: "index___en"
  }, {
    path: "/fr",
    component: _1efcffa0,
    name: "index___fr"
  }, {
    path: "/tr",
    component: _1efcffa0,
    name: "index___tr"
  }, {
    path: "/ar/blog",
    component: _4ed968c7,
    name: "blog___ar"
  }, {
    path: "/ar/calculators",
    component: _af18207c,
    name: "calculators___ar"
  }, {
    path: "/ar/contact-us",
    component: _247ecde6,
    name: "contact-us___ar"
  }, {
    path: "/ar/cooming-soon",
    component: _3a27739c,
    name: "cooming-soon___ar"
  }, {
    path: "/ar/cost",
    component: _440357ef,
    name: "cost___ar"
  }, {
    path: "/ar/doctor-dashboard",
    component: _3e5b3808,
    name: "doctor-dashboard___ar"
  }, {
    path: "/ar/empty",
    component: _0b08b2ca,
    name: "empty___ar"
  }, {
    path: "/ar/empty-default",
    component: _e5c859e2,
    name: "empty-default___ar"
  }, {
    path: "/ar/empty-default-footer",
    component: _b91d6b6e,
    name: "empty-default-footer___ar"
  }, {
    path: "/ar/empty-default-nav",
    component: _14bffb36,
    name: "empty-default-nav___ar"
  }, {
    path: "/ar/faqs",
    component: _be6a18fc,
    name: "faqs___ar"
  }, {
    path: "/ar/login",
    component: _2eb6ba92,
    name: "login___ar"
  }, {
    path: "/ar/online-treatment",
    component: _79acc6ba,
    name: "online-treatment___ar"
  }, {
    path: "/ar/patient-dashboard",
    component: _f2d7c2cc,
    name: "patient-dashboard___ar"
  }, {
    path: "/ar/quizzes",
    component: _7b54e2c4,
    name: "quizzes___ar"
  }, {
    path: "/ar/resorts",
    component: _ff8847da,
    name: "resorts___ar"
  }, {
    path: "/ar/thanks",
    component: _5821666b,
    name: "thanks___ar"
  }, {
    path: "/ar/tr",
    component: _f2c201ba,
    name: "tr___ar"
  }, {
    path: "/en/blog",
    component: _4ed968c7,
    name: "blog___en"
  }, {
    path: "/en/calculators",
    component: _af18207c,
    name: "calculators___en"
  }, {
    path: "/en/contact-us",
    component: _247ecde6,
    name: "contact-us___en"
  }, {
    path: "/en/cooming-soon",
    component: _3a27739c,
    name: "cooming-soon___en"
  }, {
    path: "/en/cost",
    component: _440357ef,
    name: "cost___en"
  }, {
    path: "/en/doctor-dashboard",
    component: _3e5b3808,
    name: "doctor-dashboard___en"
  }, {
    path: "/en/empty",
    component: _0b08b2ca,
    name: "empty___en"
  }, {
    path: "/en/empty-default",
    component: _e5c859e2,
    name: "empty-default___en"
  }, {
    path: "/en/empty-default-footer",
    component: _b91d6b6e,
    name: "empty-default-footer___en"
  }, {
    path: "/en/empty-default-nav",
    component: _14bffb36,
    name: "empty-default-nav___en"
  }, {
    path: "/en/faqs",
    component: _be6a18fc,
    name: "faqs___en"
  }, {
    path: "/en/login",
    component: _2eb6ba92,
    name: "login___en"
  }, {
    path: "/en/online-treatment",
    component: _79acc6ba,
    name: "online-treatment___en"
  }, {
    path: "/en/patient-dashboard",
    component: _f2d7c2cc,
    name: "patient-dashboard___en"
  }, {
    path: "/en/quizzes",
    component: _7b54e2c4,
    name: "quizzes___en"
  }, {
    path: "/en/resorts",
    component: _ff8847da,
    name: "resorts___en"
  }, {
    path: "/en/thanks",
    component: _5821666b,
    name: "thanks___en"
  }, {
    path: "/en/tr",
    component: _f2c201ba,
    name: "tr___en"
  }, {
    path: "/fr/blog",
    component: _4ed968c7,
    name: "blog___fr"
  }, {
    path: "/fr/calculators",
    component: _af18207c,
    name: "calculators___fr"
  }, {
    path: "/fr/contact-us",
    component: _247ecde6,
    name: "contact-us___fr"
  }, {
    path: "/fr/cooming-soon",
    component: _3a27739c,
    name: "cooming-soon___fr"
  }, {
    path: "/fr/cost",
    component: _440357ef,
    name: "cost___fr"
  }, {
    path: "/fr/doctor-dashboard",
    component: _3e5b3808,
    name: "doctor-dashboard___fr"
  }, {
    path: "/fr/empty",
    component: _0b08b2ca,
    name: "empty___fr"
  }, {
    path: "/fr/empty-default",
    component: _e5c859e2,
    name: "empty-default___fr"
  }, {
    path: "/fr/empty-default-footer",
    component: _b91d6b6e,
    name: "empty-default-footer___fr"
  }, {
    path: "/fr/empty-default-nav",
    component: _14bffb36,
    name: "empty-default-nav___fr"
  }, {
    path: "/fr/faqs",
    component: _be6a18fc,
    name: "faqs___fr"
  }, {
    path: "/fr/login",
    component: _2eb6ba92,
    name: "login___fr"
  }, {
    path: "/fr/online-treatment",
    component: _79acc6ba,
    name: "online-treatment___fr"
  }, {
    path: "/fr/patient-dashboard",
    component: _f2d7c2cc,
    name: "patient-dashboard___fr"
  }, {
    path: "/fr/quizzes",
    component: _7b54e2c4,
    name: "quizzes___fr"
  }, {
    path: "/fr/resorts",
    component: _ff8847da,
    name: "resorts___fr"
  }, {
    path: "/fr/thanks",
    component: _5821666b,
    name: "thanks___fr"
  }, {
    path: "/fr/tr",
    component: _f2c201ba,
    name: "tr___fr"
  }, {
    path: "/tr/blog",
    component: _4ed968c7,
    name: "blog___tr"
  }, {
    path: "/tr/calculators",
    component: _af18207c,
    name: "calculators___tr"
  }, {
    path: "/tr/contact-us",
    component: _247ecde6,
    name: "contact-us___tr"
  }, {
    path: "/tr/cooming-soon",
    component: _3a27739c,
    name: "cooming-soon___tr"
  }, {
    path: "/tr/cost",
    component: _440357ef,
    name: "cost___tr"
  }, {
    path: "/tr/doctor-dashboard",
    component: _3e5b3808,
    name: "doctor-dashboard___tr"
  }, {
    path: "/tr/empty",
    component: _0b08b2ca,
    name: "empty___tr"
  }, {
    path: "/tr/empty-default",
    component: _e5c859e2,
    name: "empty-default___tr"
  }, {
    path: "/tr/empty-default-footer",
    component: _b91d6b6e,
    name: "empty-default-footer___tr"
  }, {
    path: "/tr/empty-default-nav",
    component: _14bffb36,
    name: "empty-default-nav___tr"
  }, {
    path: "/tr/faqs",
    component: _be6a18fc,
    name: "faqs___tr"
  }, {
    path: "/tr/login",
    component: _2eb6ba92,
    name: "login___tr"
  }, {
    path: "/tr/online-treatment",
    component: _79acc6ba,
    name: "online-treatment___tr"
  }, {
    path: "/tr/patient-dashboard",
    component: _f2d7c2cc,
    name: "patient-dashboard___tr"
  }, {
    path: "/tr/quizzes",
    component: _7b54e2c4,
    name: "quizzes___tr"
  }, {
    path: "/tr/resorts",
    component: _ff8847da,
    name: "resorts___tr"
  }, {
    path: "/tr/thanks",
    component: _5821666b,
    name: "thanks___tr"
  }, {
    path: "/tr/tr",
    component: _f2c201ba,
    name: "tr___tr"
  }, {
    path: "/ar/landing/dental-implant1",
    component: _663c87ca,
    name: "landing-dental-implant1___ar"
  }, {
    path: "/ar/landing/dental-implant2",
    component: _662058c8,
    name: "landing-dental-implant2___ar"
  }, {
    path: "/ar/landing/dental-implant3",
    component: _660429c6,
    name: "landing-dental-implant3___ar"
  }, {
    path: "/ar/landing/dental-implant4",
    component: _65e7fac4,
    name: "landing-dental-implant4___ar"
  }, {
    path: "/ar/landing/dental-implant5",
    component: _65cbcbc2,
    name: "landing-dental-implant5___ar"
  }, {
    path: "/ar/landing/ilajak-services",
    component: _b98b2b2a,
    name: "landing-ilajak-services___ar"
  }, {
    path: "/ar/landing/ilajak-services-two",
    component: _f78e2f2c,
    name: "landing-ilajak-services-two___ar"
  }, {
    path: "/ar/landing/new-dental",
    component: _38640dd9,
    name: "landing-new-dental___ar"
  }, {
    path: "/ar/landing/new-dentistry",
    component: _2cf52213,
    name: "landing-new-dentistry___ar"
  }, {
    path: "/ar/landing/obesity-operations",
    component: _94e9caf4,
    name: "landing-obesity-operations___ar"
  }, {
    path: "/ar/online-treatment/level-1",
    component: _27e8d67c,
    name: "online-treatment-level-1___ar"
  }, {
    path: "/ar/services/turkey",
    component: _3257a710,
    name: "services-turkey___ar"
  }, {
    path: "/en/landing/dental-implant1",
    component: _663c87ca,
    name: "landing-dental-implant1___en"
  }, {
    path: "/en/landing/dental-implant2",
    component: _662058c8,
    name: "landing-dental-implant2___en"
  }, {
    path: "/en/landing/dental-implant3",
    component: _660429c6,
    name: "landing-dental-implant3___en"
  }, {
    path: "/en/landing/dental-implant4",
    component: _65e7fac4,
    name: "landing-dental-implant4___en"
  }, {
    path: "/en/landing/dental-implant5",
    component: _65cbcbc2,
    name: "landing-dental-implant5___en"
  }, {
    path: "/en/landing/ilajak-services",
    component: _b98b2b2a,
    name: "landing-ilajak-services___en"
  }, {
    path: "/en/landing/ilajak-services-two",
    component: _f78e2f2c,
    name: "landing-ilajak-services-two___en"
  }, {
    path: "/en/landing/new-dental",
    component: _38640dd9,
    name: "landing-new-dental___en"
  }, {
    path: "/en/landing/new-dentistry",
    component: _2cf52213,
    name: "landing-new-dentistry___en"
  }, {
    path: "/en/landing/obesity-operations",
    component: _94e9caf4,
    name: "landing-obesity-operations___en"
  }, {
    path: "/en/online-treatment/level-1",
    component: _27e8d67c,
    name: "online-treatment-level-1___en"
  }, {
    path: "/en/services/turkey",
    component: _3257a710,
    name: "services-turkey___en"
  }, {
    path: "/fr/landing/dental-implant1",
    component: _663c87ca,
    name: "landing-dental-implant1___fr"
  }, {
    path: "/fr/landing/dental-implant2",
    component: _662058c8,
    name: "landing-dental-implant2___fr"
  }, {
    path: "/fr/landing/dental-implant3",
    component: _660429c6,
    name: "landing-dental-implant3___fr"
  }, {
    path: "/fr/landing/dental-implant4",
    component: _65e7fac4,
    name: "landing-dental-implant4___fr"
  }, {
    path: "/fr/landing/dental-implant5",
    component: _65cbcbc2,
    name: "landing-dental-implant5___fr"
  }, {
    path: "/fr/landing/ilajak-services",
    component: _b98b2b2a,
    name: "landing-ilajak-services___fr"
  }, {
    path: "/fr/landing/ilajak-services-two",
    component: _f78e2f2c,
    name: "landing-ilajak-services-two___fr"
  }, {
    path: "/fr/landing/new-dental",
    component: _38640dd9,
    name: "landing-new-dental___fr"
  }, {
    path: "/fr/landing/new-dentistry",
    component: _2cf52213,
    name: "landing-new-dentistry___fr"
  }, {
    path: "/fr/landing/obesity-operations",
    component: _94e9caf4,
    name: "landing-obesity-operations___fr"
  }, {
    path: "/fr/online-treatment/level-1",
    component: _27e8d67c,
    name: "online-treatment-level-1___fr"
  }, {
    path: "/fr/services/turkey",
    component: _3257a710,
    name: "services-turkey___fr"
  }, {
    path: "/tr/landing/dental-implant1",
    component: _663c87ca,
    name: "landing-dental-implant1___tr"
  }, {
    path: "/tr/landing/dental-implant2",
    component: _662058c8,
    name: "landing-dental-implant2___tr"
  }, {
    path: "/tr/landing/dental-implant3",
    component: _660429c6,
    name: "landing-dental-implant3___tr"
  }, {
    path: "/tr/landing/dental-implant4",
    component: _65e7fac4,
    name: "landing-dental-implant4___tr"
  }, {
    path: "/tr/landing/dental-implant5",
    component: _65cbcbc2,
    name: "landing-dental-implant5___tr"
  }, {
    path: "/tr/landing/ilajak-services",
    component: _b98b2b2a,
    name: "landing-ilajak-services___tr"
  }, {
    path: "/tr/landing/ilajak-services-two",
    component: _f78e2f2c,
    name: "landing-ilajak-services-two___tr"
  }, {
    path: "/tr/landing/new-dental",
    component: _38640dd9,
    name: "landing-new-dental___tr"
  }, {
    path: "/tr/landing/new-dentistry",
    component: _2cf52213,
    name: "landing-new-dentistry___tr"
  }, {
    path: "/tr/landing/obesity-operations",
    component: _94e9caf4,
    name: "landing-obesity-operations___tr"
  }, {
    path: "/tr/online-treatment/level-1",
    component: _27e8d67c,
    name: "online-treatment-level-1___tr"
  }, {
    path: "/tr/services/turkey",
    component: _3257a710,
    name: "services-turkey___tr"
  }, {
    path: "/ar/services/turkey/dentistry-turkey",
    component: _f958463a,
    name: "services-turkey-dentistry-turkey___ar"
  }, {
    path: "/ar/services/turkey/hair-transplant-turkey",
    component: _ad8d6c02,
    name: "services-turkey-hair-transplant-turkey___ar"
  }, {
    path: "/ar/services/turkey/obesity-operations-turkey",
    component: _1c6fc9ed,
    name: "services-turkey-obesity-operations-turkey___ar"
  }, {
    path: "/ar/services/turkey/vision-correction",
    component: _5f00b321,
    name: "services-turkey-vision-correction___ar"
  }, {
    path: "/en/services/turkey/dentistry-turkey",
    component: _f958463a,
    name: "services-turkey-dentistry-turkey___en"
  }, {
    path: "/en/services/turkey/hair-transplant-turkey",
    component: _ad8d6c02,
    name: "services-turkey-hair-transplant-turkey___en"
  }, {
    path: "/en/services/turkey/obesity-operations-turkey",
    component: _1c6fc9ed,
    name: "services-turkey-obesity-operations-turkey___en"
  }, {
    path: "/en/services/turkey/vision-correction",
    component: _5f00b321,
    name: "services-turkey-vision-correction___en"
  }, {
    path: "/fr/services/turkey/dentistry-turkey",
    component: _f958463a,
    name: "services-turkey-dentistry-turkey___fr"
  }, {
    path: "/fr/services/turkey/hair-transplant-turkey",
    component: _ad8d6c02,
    name: "services-turkey-hair-transplant-turkey___fr"
  }, {
    path: "/fr/services/turkey/obesity-operations-turkey",
    component: _1c6fc9ed,
    name: "services-turkey-obesity-operations-turkey___fr"
  }, {
    path: "/fr/services/turkey/vision-correction",
    component: _5f00b321,
    name: "services-turkey-vision-correction___fr"
  }, {
    path: "/tr/services/turkey/dentistry-turkey",
    component: _f958463a,
    name: "services-turkey-dentistry-turkey___tr"
  }, {
    path: "/tr/services/turkey/hair-transplant-turkey",
    component: _ad8d6c02,
    name: "services-turkey-hair-transplant-turkey___tr"
  }, {
    path: "/tr/services/turkey/obesity-operations-turkey",
    component: _1c6fc9ed,
    name: "services-turkey-obesity-operations-turkey___tr"
  }, {
    path: "/tr/services/turkey/vision-correction",
    component: _5f00b321,
    name: "services-turkey-vision-correction___tr"
  }, {
    path: "/ar/blog/category/:category?",
    component: _07d9e032,
    name: "blog-category-category___ar"
  }, {
    path: "/ar/online-treatment/topic/:slug",
    component: _3cb4608a,
    name: "online-treatment-topic-slug___ar"
  }, {
    path: "/ar/services/turkey/:slug",
    component: _30a105c8,
    name: "services-turkey-slug___ar"
  }, {
    path: "/en/blog/category/:category?",
    component: _07d9e032,
    name: "blog-category-category___en"
  }, {
    path: "/en/online-treatment/topic/:slug",
    component: _3cb4608a,
    name: "online-treatment-topic-slug___en"
  }, {
    path: "/en/services/turkey/:slug",
    component: _30a105c8,
    name: "services-turkey-slug___en"
  }, {
    path: "/fr/blog/category/:category?",
    component: _07d9e032,
    name: "blog-category-category___fr"
  }, {
    path: "/fr/online-treatment/topic/:slug",
    component: _3cb4608a,
    name: "online-treatment-topic-slug___fr"
  }, {
    path: "/fr/services/turkey/:slug",
    component: _30a105c8,
    name: "services-turkey-slug___fr"
  }, {
    path: "/tr/blog/category/:category?",
    component: _07d9e032,
    name: "blog-category-category___tr"
  }, {
    path: "/tr/online-treatment/topic/:slug",
    component: _3cb4608a,
    name: "online-treatment-topic-slug___tr"
  }, {
    path: "/tr/services/turkey/:slug",
    component: _30a105c8,
    name: "services-turkey-slug___tr"
  }, {
    path: "/ar/blog/:slug",
    component: _4d22c77f,
    name: "blog-slug___ar"
  }, {
    path: "/ar/calculators/:slug",
    component: _b285630c,
    name: "calculators-slug___ar"
  }, {
    path: "/ar/doctors/:country",
    component: _19a6b612,
    name: "doctors-country___ar"
  }, {
    path: "/ar/faqs/:slug",
    component: _c1d75b8c,
    name: "faqs-slug___ar"
  }, {
    path: "/ar/hospitals/:country",
    component: _46463c12,
    name: "hospitals-country___ar"
  }, {
    path: "/ar/quizzes/:slug",
    component: _799e417c,
    name: "quizzes-slug___ar"
  }, {
    path: "/ar/resorts/:slug",
    component: _7e853acb,
    name: "resorts-slug___ar"
  }, {
    path: "/ar/tags/:slug?",
    component: _a7763994,
    name: "tags-slug___ar"
  }, {
    path: "/en/blog/:slug",
    component: _4d22c77f,
    name: "blog-slug___en"
  }, {
    path: "/en/calculators/:slug",
    component: _b285630c,
    name: "calculators-slug___en"
  }, {
    path: "/en/doctors/:country",
    component: _19a6b612,
    name: "doctors-country___en"
  }, {
    path: "/en/faqs/:slug",
    component: _c1d75b8c,
    name: "faqs-slug___en"
  }, {
    path: "/en/hospitals/:country",
    component: _46463c12,
    name: "hospitals-country___en"
  }, {
    path: "/en/quizzes/:slug",
    component: _799e417c,
    name: "quizzes-slug___en"
  }, {
    path: "/en/resorts/:slug",
    component: _7e853acb,
    name: "resorts-slug___en"
  }, {
    path: "/en/tags/:slug?",
    component: _a7763994,
    name: "tags-slug___en"
  }, {
    path: "/fr/blog/:slug",
    component: _4d22c77f,
    name: "blog-slug___fr"
  }, {
    path: "/fr/calculators/:slug",
    component: _b285630c,
    name: "calculators-slug___fr"
  }, {
    path: "/fr/doctors/:country",
    component: _19a6b612,
    name: "doctors-country___fr"
  }, {
    path: "/fr/faqs/:slug",
    component: _c1d75b8c,
    name: "faqs-slug___fr"
  }, {
    path: "/fr/hospitals/:country",
    component: _46463c12,
    name: "hospitals-country___fr"
  }, {
    path: "/fr/quizzes/:slug",
    component: _799e417c,
    name: "quizzes-slug___fr"
  }, {
    path: "/fr/resorts/:slug",
    component: _7e853acb,
    name: "resorts-slug___fr"
  }, {
    path: "/fr/tags/:slug?",
    component: _a7763994,
    name: "tags-slug___fr"
  }, {
    path: "/tr/blog/:slug",
    component: _4d22c77f,
    name: "blog-slug___tr"
  }, {
    path: "/tr/calculators/:slug",
    component: _b285630c,
    name: "calculators-slug___tr"
  }, {
    path: "/tr/doctors/:country",
    component: _19a6b612,
    name: "doctors-country___tr"
  }, {
    path: "/tr/faqs/:slug",
    component: _c1d75b8c,
    name: "faqs-slug___tr"
  }, {
    path: "/tr/hospitals/:country",
    component: _46463c12,
    name: "hospitals-country___tr"
  }, {
    path: "/tr/quizzes/:slug",
    component: _799e417c,
    name: "quizzes-slug___tr"
  }, {
    path: "/tr/resorts/:slug",
    component: _7e853acb,
    name: "resorts-slug___tr"
  }, {
    path: "/tr/tags/:slug?",
    component: _a7763994,
    name: "tags-slug___tr"
  }, {
    path: "/ar/doctors/:country?/:city",
    component: _1057ec9c,
    name: "doctors-country-city___ar"
  }, {
    path: "/ar/hospitals/:country?/:city",
    component: _3568a08d,
    name: "hospitals-country-city___ar"
  }, {
    path: "/en/doctors/:country?/:city",
    component: _1057ec9c,
    name: "doctors-country-city___en"
  }, {
    path: "/en/hospitals/:country?/:city",
    component: _3568a08d,
    name: "hospitals-country-city___en"
  }, {
    path: "/fr/doctors/:country?/:city",
    component: _1057ec9c,
    name: "doctors-country-city___fr"
  }, {
    path: "/fr/hospitals/:country?/:city",
    component: _3568a08d,
    name: "hospitals-country-city___fr"
  }, {
    path: "/tr/doctors/:country?/:city",
    component: _1057ec9c,
    name: "doctors-country-city___tr"
  }, {
    path: "/tr/hospitals/:country?/:city",
    component: _3568a08d,
    name: "hospitals-country-city___tr"
  }, {
    path: "/ar/doctors/:country?/:city/:specialization",
    component: _be08dce6,
    name: "doctors-country-city-specialization___ar"
  }, {
    path: "/ar/hospitals/:country?/:city/:slug",
    component: _d45d83f0,
    name: "hospitals-country-city-slug___ar"
  }, {
    path: "/en/doctors/:country?/:city/:specialization",
    component: _be08dce6,
    name: "doctors-country-city-specialization___en"
  }, {
    path: "/en/hospitals/:country?/:city/:slug",
    component: _d45d83f0,
    name: "hospitals-country-city-slug___en"
  }, {
    path: "/fr/doctors/:country?/:city/:specialization",
    component: _be08dce6,
    name: "doctors-country-city-specialization___fr"
  }, {
    path: "/fr/hospitals/:country?/:city/:slug",
    component: _d45d83f0,
    name: "hospitals-country-city-slug___fr"
  }, {
    path: "/tr/doctors/:country?/:city/:specialization",
    component: _be08dce6,
    name: "doctors-country-city-specialization___tr"
  }, {
    path: "/tr/hospitals/:country?/:city/:slug",
    component: _d45d83f0,
    name: "hospitals-country-city-slug___tr"
  }, {
    path: "/ar/doctors/:country?/:city/:specialization/:slug",
    component: _49ab6f08,
    name: "doctors-country-city-specialization-slug___ar"
  }, {
    path: "/en/doctors/:country?/:city/:specialization/:slug",
    component: _49ab6f08,
    name: "doctors-country-city-specialization-slug___en"
  }, {
    path: "/fr/doctors/:country?/:city/:specialization/:slug",
    component: _49ab6f08,
    name: "doctors-country-city-specialization-slug___fr"
  }, {
    path: "/tr/doctors/:country?/:city/:specialization/:slug",
    component: _49ab6f08,
    name: "doctors-country-city-specialization-slug___tr"
  }, {
    path: "/ar/:slug",
    component: _1d465e58,
    name: "slug___ar"
  }, {
    path: "/en/:slug",
    component: _1d465e58,
    name: "slug___en"
  }, {
    path: "/fr/:slug",
    component: _1d465e58,
    name: "slug___fr"
  }, {
    path: "/tr/:slug",
    component: _1d465e58,
    name: "slug___tr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
