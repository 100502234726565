//
//
//
//

export default {
  name: "empty",
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      script: [
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/js/all.min.js",
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href:
            this.$i18n.locale == "ar"
              ? "https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css"
              : "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css",
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css"
        },
        {
          rel: "stylesheet",
          href:
            this.$i18n.locale == "ar"
              ? "https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css"
              : "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css",
        },

        {
          rel: "stylesheet",
          href:
            this.$i18n.locale == "ar"
              ? "https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap"
              : "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap",
          defer: true,
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/vue-multiselect/2.1.6/vue-multiselect.min.css",
          defer: true,
        },
        {
          rel: "stylesheet",
          href: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.4.5/css/swiper.min.css",
          defer: true,
        },
      ],
      bodyAttrs: {
        class:
          this.$i18n.locale == "ar"
            ? "rtl p-0 background-primary"
            : "ltr p-0 background-primary",
      },
    };
  },
  mounted() {
    this.$store.dispatch("getUserLocation");
  },
};
