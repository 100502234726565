export default async function ({ app,$axios,i18n  }) {
    $axios.setHeader('X-localization' , i18n.locale,)
    if (!app.$auth.loggedIn) {
        return
    } else {
        const auth = app.$auth;
        const authStrategy = auth.strategy.name;
        if (authStrategy === 'facebook') {
            let dataFacebook = {
                provider_id: auth.user.id,
                name: auth.user.name,
                email:auth.user.email,
                provider:'facebook',
            }
            try {
                await app.$axios.$post("login/social", dataFacebook);
            } catch (e) {
                //console.log(e);
            }
        } else if (authStrategy === 'google') {
            let dataGoogle = {
                provider_id: auth.user.sub,
                name: auth.user.given_name + auth.user.family_name,
                email:auth.user.email,
                avatar:auth.user.picture,
                provider:'google',
            }
            try {
                await app.$axios.$post("login/social", dataGoogle);
            } catch (e) {
               // console.log(e);
            }
        }
    }
}
