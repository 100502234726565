export const state = () => ({
  socialMedia: [],
  generaleSettings: [],
  loginStatus: 0,
  userInfoModal: {},
  caseReviewModal: {},
  reviewModal: {},
  reportMeetingModal: {},
  stopMeetingModal: {},
  userLocation: 'TR',
  availableLanguages: ['ar', 'en', 'fr']
})

export const mutations = {
  // SET_USER_LOCATION(state, comingUserLocation) {
  //     state.userLocation = comingUserLocation
  //
  // },
  ALL_LANGUAGES(state) {
    state.availableLanguages = ['ar', 'en', 'fr']
  },
  SET_AVAILABLE_LANGUAGES(state, payload) {
      state.availableLanguages = payload
  },
  EMPTY_LANGUAGES(state) {
    state.availableLanguages = []
  },
  SET_SOCIAL_MEDIA(state, socialMedia) {
    state.socialMedia = socialMedia
  },
  SET_GENERALE_SETTINGS(state, generaleSettings) {
    state.generaleSettings = generaleSettings
  },
  SET_LOGIN_STATUS(state, status) {
    state.loginStatus = status
  },
  SET_USER_INFO_MODAL(state, userInfoModal) {
    state.userInfoModal = userInfoModal
  },
  SET_CASE_REVIEW_MODAL(state, caseReviewModal) {
    state.caseReviewModal = caseReviewModal
  },
  SET_REVIEW_MODAL(state, reviewModal) {
    state.reviewModal = reviewModal
  },
  SET_REPORT_MEETING_MODAL(state, reportMeetingModal) {
    state.reportMeetingModal = reportMeetingModal
  },
  SET_STOP_MEETING_MODAL(state, stopMeetingModal) {
    state.stopMeetingModal = stopMeetingModal
  },
}

export const actions = {
//   async getUserLocation({commit}) {
//     const response = await this.$axios.get(`https://cloudflare-quic.com/b/headers`);
//     commit('SET_USER_LOCATION',response.data.headers["Cf-Ipcountry"])
// },
  async setAvailableLanguages({commit}, langArr) {
    commit('SET_AVAILABLE_LANGUAGES', langArr)
  }, async AllLanguages({commit}, allLangs) {
    commit('ALL_LANGUAGES', allLangs)
  },
  async setEmptyLanguages({commit}) {
    commit('EMPTY_LANGUAGES')
  },
  async getSocialMedia({commit}) {
    const response = await this.app.$api.fetchSocialMedia()
    commit('SET_SOCIAL_MEDIA', response.content.items)
  },

  async getGeneraleSettings({commit}) {
    const response = await this.app.$api.fetchGeneraleSettings()
    commit('SET_GENERALE_SETTINGS', response.content.items)
  },

  async changeLoginStatus({commit}, status) {
    commit('SET_LOGIN_STATUS', status)
  },

  async setUserInfoModal({commit}, userInfoModal) {
    commit('SET_USER_INFO_MODAL', userInfoModal)
  }, async setCaseReviewModal({commit}, caseReviewModal) {
    commit('SET_CASE_REVIEW_MODAL', caseReviewModal)
  }, async setReviewModal({commit}, reviewModal) {
    commit('SET_REVIEW_MODAL', reviewModal)
  }, async setReportMeetingModal({commit}, reportMeetingModal) {
    commit('SET_REPORT_MEETING_MODAL', reportMeetingModal)
  }, async setStopMeetingModal({commit}, stopMeetingModal) {
    commit('SET_STOP_MEETING_MODAL', stopMeetingModal)
  },
}
